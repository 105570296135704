<template>
	<modal :id="ref" :ref="ref" :closeProp="close" title="Files">
		<label>Carica File</label>
		<div class="custom-file">
			<input
				ref="fileInput"
				type="file"
				class="custom-file-input"
				:class="{ 'is-invalid': !!error }"
				id="uploadfile"
				title="Carica File"
				:required="true"
				:multiple="multiple"
				@change="inputChange"
			/>
			<label class="custom-file-label" for="uploadfile">{{
				!!placeholder ? placeholder : placeholderDefault
			}}</label>
			<span class="invalid-feedback" role="alert" v-if="!!error">
				<strong>{{ error }}</strong>
			</span>
		</div>

		<template v-slot:footer>
			<btn type="success" :icon="['fad', 'check']" title="Carica" :action="uploadFile"></btn>
		</template>
	</modal>
</template>

<script>
export default {
	data: () => ({
		ref: 'file_modal',
		error: false,
		placeholder: '',
		placeholderDefault: 'Selezionare...',
	}),
	props: {
		afterupload: {
			type: Function,
			default: null,
			required: true,
		},
		multiple: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		close: function() {
			this.$refs[this.ref].close()
			this.$refs.fileInput.value = ''
			this.placeholder = ''
		},
		open: function() {
			this.$refs[this.ref].open()
		},
		inputChange: function(event) {
			let names = []
			for (var i = 0; i < event.target.files.length; i++) {
				names.push(event.target.files[i].name)
			}

			this.placeholder = names.join(', ')
		},
		uploadFile: function() {
			let fileInput = this.$refs.fileInput

			if (!fileInput.files || (!fileInput.files && fileInput.files.length == 0)) {
				this.error = 'Il campo è obbligatorio'

				return false
			}

			let params = new FormData()

			for (var i = 0; i < fileInput.files.length; i++) {
				params.append('files[]', fileInput.files[i])
			}

			axios({
				url: '/api/v1/files',
				method: 'POST',
				data: params,
				headers: {
					Accept: 'application/json; charset=utf-8',
					'Content-Type': 'multipart/form-data',
				},
			})
				.then(({ data, status, statusText }) => {
					this.error = false

					this.afterupload(data)
					this.close()
				})
				.catch(({ response, request, message, config }) => {
					this.error = !!response.data.error
						? response.data.error.join(', ')
						: 'Il file non è stato caricato correttamente'
					return false
				})
		},
	},
}
</script>
